import React from "react";
import AOS from "aos";
import $ from 'jquery';
import { Routes, Route } from 'react-router-dom';
//import { BrowserView, MobileView } from 'react-device-detect';

import Header from './Header';
import Footer from './Footer';
import TestStart from './TestStart';
import Choice from "./stage/Choices";
import CatQuest from "./stage/cat/CatQuest";
import CatAnswer from "./stage/cat/CatAnswer";
import Result from "./stage/cat/Result";
import DogQuest from "./stage/dog/DogQuest";
import DogAnswer from "./stage/dog/DogAnswer";
import DogResult from "./stage/dog/Result";
//import Option from "./stage/cat/Option";
//import Repository from './Repository';

import "aos/dist/aos.css";
import './App.css';

class App extends React.Component {
  
  
  componentDidMount(){
    $(function(){
      $(".header").addClass("on");
      $(window).scroll(function() {
          var wTop = $(window).scrollTop();
          //header scroll
          var header_height = $(".testComm .scroll-box").height();
          // console.log('header_height:'+header_height);
          // console.log('wTop:'+wTop);
          if (wTop < header_height){
            $(".bt_fixed").css('display','none');
          }else{
            $(".bt_fixed").css('display','block');
            
          }
      });

      //[Footer] : 제휴사이트
      $(".partner .btn_site").click(function(){
          $(".partner").toggleClass("on");
      });
      $(".partner .site_list li").click(function(){
          $(".partner").removeClass("on");
      });

      //[Footer] : ScrollTop 버튼
      $(".btn_scroll_top").click(function(){
          $('html, body').animate({
              scrollTop : 0
          },500);
          return false;
      });
      //[Header] : Mobile
        $(".btn_menu").click(function(){
          $(this).toggleClass("on");
          //$(this).siblings(".gnb_box").toggleClass("on");
          $(this).siblings(".gnb_box").slideToggle();
          
          if($(this).hasClass("on")){
              $('body').addClass('scroll_off');
          }else{
              $('body').removeClass('scroll_off')
          }
      });
      $(".header .mobile .depth_2").click(function(){
          $(this).toggleClass("on");
      });

     
      $(".btn_share").click( function() {  
        if (navigator.share) {
            navigator.share({
              title: '집사의 자격',
              content: '\n\n나의 집사력은 어느 정도 일까?\n\n 테스트 하러가기 >>\n',
              url: 'https://zipsa.swygbro.com'
            }).then(() => {
              console.log('Thanks for sharing!');
            })
            console.log('Thanks for sharing!');
        } else {
          // Fallback
        }
     });
      
      //Tab Menu
      $(".tab_box .tab_menu").click(function(){
          var tab = $(this).parents(".tab_box").data("tab");
          tab = $(".tab_box[data-tab='"+tab+"']");
          var tab_menu = $(this).data("menu");
          
          if(tab.find($(".tab_menu")).hasClass("on")){
              tab.find($(".tab_menu, .tab_con")).removeClass("on");
          }
          
          $(this).addClass("on");
          tab.find($(".tab_con[data-con='"+tab_menu+"']")).addClass("on");
      });

      //Select Box
      $(".select_box .btn_select").click(function(){
          $(this).parent(".select_box").toggleClass("on");
      });
      $(".select_box .list li").click(function(){
          $(this).parents(".select_box").removeClass("on");
          var item = $(this).find("a").text();
          $(this).parent().siblings(".btn_select").text(item);
      });

      const modal = document.querySelector('.modal');
      const btnOpenPopup = document.querySelector('.btn-open-popup');

      btnOpenPopup.addEventListener('click', () => {
        window.open("/","미리보기","width=420,height=917,menubar=no,location=no,resizable=no,scrollbars=no,status=no");
      });
    });
  };

  constructor(props) {
    super(props);
    AOS.init({
      duration: 500
    });
  }

  render(){
    
    return (
      <>
       <Header />
      {
            window.innerWidth < 768
            ? (
          <Routes>
            <Route path="/" element={<TestStart />}></Route>
            <Route path="/stage" element={<Choice />}></Route>
            <Route path="/stage/cat" element={<CatQuest />}></Route>
            <Route path="/stage/cat/check" element={<CatAnswer />}></Route>
            <Route path="/stage/cat/result" element={<Result />}></Route>
            <Route path="/stage/dog" element={<DogQuest />}></Route>
            <Route path="/stage/dog/check" element={<DogAnswer />}></Route>
            <Route path="/stage/dog/result" element={<DogResult />}></Route>
          </Routes>
          )
          :(
          <div class="browser">
            <h1 className="title">모바일 환경으로 접속해주세요</h1><br /><br />
            <h2 className="description">
              아쉽게도 PC는 현재 작업이 진행중 입니다.😥 <br />
              모바일 환경에서 '집사의 자격'을 이용해주세요!
            </h2>
            <br/><br/>
            <button class="btn-open-popup">모바일 화면으로 테스트 해보기 ▶</button>
          </div>
        )
       }
          <Footer />
        </>
    );
  };
  
};

export default App;
