import $ from "jquery";
import img from "../../image/pointer.png";

import React,{useState,useEffect} from 'react';
const  Loading = (prop) =>{

    useEffect(()=>{
        window.scrollTo(0, 0);
       if(prop===''){
        $('.stage-name').css('color',"#c4c4c4");
       }

      }, []);

    return(
        <div class="result_loading">
            <div class="icon">
                <img src={img}/>
            </div>
            <br/>
            <div class="stage-name">결과 분석중</div>
        </div>
    )
}

export default Loading;