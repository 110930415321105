import React,{useState,useEffect} from 'react';
import { useLocation } from "react-router-dom"; // 추가된 부분
import { Cookies } from 'react-cookie';
import Comment from './Comment';
import Check from './Check';


const CatAnswer = () => {
    const cookies = new Cookies();

    const location = useLocation(); // 추가된 부분
    const stage = location.state?.stage; // 추가된 부분
    const number = location.state?.number; // 추가된 부분
    const score = location.state?.score; // 추가된 부분
    const txt = location.state?.txt; // 추가된 부분
    const testScore = stage+'-'+number

    const [isOpen, setShow] = useState(false);
    const [dataQ,setQData]=useState([]);
    const [title,setTitle]=useState([]);
    cookies.remove(testScore,score);
    cookies.set(testScore,score);

    useEffect(()=>{
        let timer = setTimeout(()=>{ setShow(true); }, 1000);
    });

    const getTitle=()=>{
        fetch("https://zipsa-testing-default-rtdb.firebaseio.com/quest/dog/"+stage+"/title.json"
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            return response.json();
          })
          .then(function(myJson) {
            setTitle(myJson)
          });
      }
    const getQData=()=>{
        fetch("https://zipsa-testing-default-rtdb.firebaseio.com/quest/dog/"+stage+"/quest/"+number+".json"
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            return response.json();
          })
          .then(function(myJson) {
            setQData(myJson['cmt'])
          });
      }
      
      useEffect(()=>{
        window.scrollTo(0, 0);
        getQData();
        getTitle();
      },[])

    
    return(
    <div class="answer dog"> 
        {
            isOpen === false
            ? (
                
                <Check score={score}/>
               
            ): (
                <Comment cmt={dataQ} score={score} stage={stage} number={number} title={title}/>
            )

        }
         </div>
    )
}

export default CatAnswer;