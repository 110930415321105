import {useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import logo from './image/logo.png';
import main from './image/main_new.png';
import dogs from './image/main-2-new.png';
import { firebaseDB ,firebaseAuth} from './DB';
import { collection, getDocs, doc,addDoc,updateDoc } from 'firebase/firestore'
import { Swiper, SwiperSlide } from "swiper/react"; // basic
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css"; //basic
import "swiper/css/navigation";
import "swiper/css/pagination";

import result1 from "./image/result1_2023.png";
import result2 from "./image/result2_2023.png";
import result3 from "./image/result3_2023.png";
import result4 from "./image/result4_2023.png";
import result1_dog from "./image/dog/result1.png";
import result2_dog from "./image/dog/result2.png";
import result3_dog from "./image/dog/result3.png";
import result4_dog from "./image/dog/result4.png";

SwiperCore.use([Navigation, Pagination])

const TestStart = (props) => {

    var participant = 0;
    fetch("https://zipsa-testing-default-rtdb.firebaseio.com/check/participant.json"
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        participant = myJson;
      });

        const [dataReal,setRealData]=useState([]);

        const docRef = async () =>{
            var num =  parseInt(dataReal)+1
            console.log(typeof num)
            console.log(typeof dataReal)
            await updateDoc(doc(firebaseDB, 'click','1314hkU6ojCqthkiSwat'), {
                counts: num,
            });
            window.location.href="/stage";
        };


        const getData = async () =>{
            const query = await getDocs(collection(firebaseDB, 'click'));
            query.forEach((doc) => {
                setRealData (doc.data().counts)
            });
        }

        useEffect(() => {
            getData() 
            window.scrollTo(0, 0);
            //console.log(dataReal);
            //docRef();
        },[]);

        function testStart(){
            docRef();
        }

    return (
        <>
            <header class="header">
                <div class="pc">
                    <div class="h_wrap">
                        <a href="/" class="logo">
                            <img src={logo} alt="집사의 자격" />
                        </a>
                        <div class="member_box">
                            <div class="member on">
                                <a href="javascript:;" class="btn_share" ><i class="fas fa-external-link-alt"></i></a>
                                <div class="user_box">
                                    <a href="./mypage.asp" class="btn_index"><i class="fas fa-bars"></i></a>
                                    <ul class="user_menu">
                                        <li><a href="./mypage.asp">서비스 소개</a></li>
                                        <li><a href="./my_class.asp">반려동물 레포</a></li>
                                        <li><button class="btn_logout">유기동물 레포</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile">
                    <a href="/" class="logo">
                        <img src={logo} alt="집사의 자격" />
                    </a>
                    <a href="javascript:;" class="btn_share">
                    <span> <i class="fas fa-external-link-alt"></i></span>
                    </a>
                    <a href="javascript:;" class="btn_menu">
                        <div class="blind">Menu</div>
                        <span> </span>
                        <span> </span>
                        <span> </span>
                    </a>
                    <div class="gnb_box">
                        <ul class="gnb">
                            <li><a href="/">서비스 소개 (준비중)</a></li>
                            <li><a href="/">반려동물 레포 (준비중)</a></li>
                            <li><a href="/">유기동물 레포 (준비중)</a></li>
                        </ul>
                        <div class="btn_box">
                            <button class="btn btn_logout">공유</button>
                        </div>
                    </div>
                </div>
            </header>
            <article class="testStart">
                <div class="contents_wrap">
                    <p class="tit_txt" data-aos="fade-up">반려동물 테스트</p>
                    <br />
                    <h1 class="title" data-aos="fade-up" >집사가 될 수 있을까?</h1>
                    <div class="img_box" data-aos="fade-up" data-aos-delay="200"><img src={main} /></div>
                    <div class="btn_box">
                        <p class="test_count" data-aos="fade-up" data-aos-delay="200">{dataReal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명이 참여했어요! {/*355명이 참여했어요! 2023-01-19 기준 <span class="hint">* 2023-01-31 기준</span> */}</p> 
                    </div>
                    <p class="test_detail" data-aos="fade-up">
                        당신은 반려동물을 키울 준비가 되었나요?<br />
                        흔히 귀엽다고, 외로워서의 이유를 벗어나 사랑스러운 동물친구들과<br/> 천년만년 지낼 준비가 되었는지<br />
                        간단한 집사의 자격을 확인해보세요.
                    </p>
                    <div class="scroll_next">
                        <i class='fas fa-angle-down'></i>
                    </div>
                </div>
            </article>
            <article class="testComm">
            <div class="scroll-box">
                <ul class="neon-sign-left"  data-aos="fade-up" data-aos-delay="200">
                    <li>외로운데 동물이나 키워볼까?</li>
                    <li>애교냥이는 어디서 구할수 있지?</li>
                    <li>보호소 동물들은 문제가 있지 않을끼?</li>
                    <li>개가 사람을 무네? 광견병 아니야?</li>
                    <li>고양이도 분리불안이 있다고?</li>
                    <li>중성화 꼭 해야하나?</li>
                </ul>
                <ul class="neon-sign-right"  data-aos="fade-up" data-aos-delay="200">
                    <li>개가 사람을 무네? 광견병 아니야?</li>
                    <li>고양이도 분리불안이 있다고?</li>
                    <li>중성화 꼭 해야하나?</li>
                    <li>벤토, 카사바, 두부, 펠렛? 이게 뭐야?</li>
                    <li>개는 산책을 몇번이나 해야 하는거야?</li>
                    <li>똑똑한 강아지들은 키우기 편하겠지?</li>
                </ul>
                <ul class="neon-sign-left"  data-aos="fade-up" data-aos-delay="200">
                    <li>벤토, 카사바, 두부, 펠렛? 이게 뭐야?</li>
                    <li>개는 산책을 몇번이나 해야 하는거야?</li>
                    <li>똑똑한 강아지들은 키우기 편하겠지?</li>
                    <li>외로운데 동물키워볼까?</li>
                    <li>애교냥이는 어디서 구할수 있지?</li>
                    <li>보호소 동물들은 문제가 있지 않을끼?</li>
                </ul>
            </div>
            <div class="contents_wrap">
                <div class="img_box" data-aos="fade-up" data-aos-delay="200"><img src={dogs} /></div>
                <div class="sub">
                    <p class="tit_txt" data-aos="fade-up" data-aos-delay="200">귀여운 인절미~ 액체냥 고먐미<br/>항상 나만 없던 반려동물 친구들!<br/>이제 나도 가족으로 데려올래..!!</p>
                </div>
                <div class="sub">
                    <h1 class="title" data-aos="fade-up" data-aos-delay="200">잠깐!</h1>
                </div>
                <div class="sub">
                    <p class="tit_txt" data-aos="fade-up" data-aos-delay="200">지금 나의 환경조건이 <br/>아이들을 받아들일 준비가 되었는지<br/>항상 고민되고, 걱정되었나요?</p>
                </div>
                <div class="sub">
                    <p class="tit_txt" data-aos="fade-up" data-aos-delay="200">저희가 준비한 테스트로 <br/> 나는 집사가 될 준비가 되었는지 <br/>확인해보실 수 있어요</p>
                </div>
            </div>
        </article>
        <article class="testResult">
            <div class="contenrs_wrap">
                <br /> <br />
                <h1 class="title" data-aos="fade-up" >집사의 자격증<br/>미리 만나보세요!</h1>
                <div class="sub">
                    <p class="tit_txt" data-aos="fade-up" data-aos-delay="200">테스트 결과에 맞춰<br/>집사 자격증을 부여합니다.<br/>어떤 레벨이 있는지 확인해볼까요?</p>
                </div>
            </div>
            <br />
            <div class="contenrs_wrap">
                <div class="swiper-edit">
                    <Swiper
                        className="video_slide"
                        spaceBetween={20}
                        slidesPerView={1.4}
                        navigation
                        pagination={{ clickable: true, bottom: 0}}
                        loop={true}
                        centeredSlides={true}
                        breakpoints={{
                            768: {
                            slidesPerView: 1,
                            },
                        }}
                        >
                                <SwiperSlide>
                                <img src={result1} class="video" alt="결과지 1" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={result2} class="video" alt="결과지 2" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={result3} class="video" alt="결과지 3" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={result4} class="video" alt="결과지 4" />
                                </SwiperSlide>
                        
                    </Swiper>
                </div>
                <br/><br/>
                <div class="swiper-edit">
                    <Swiper
                        className="video_slide"
                        spaceBetween={20}
                        slidesPerView={1.4}
                        navigation
                        pagination={{ clickable: true, bottom: 0}}
                        loop={true}
                        centeredSlides={true}
                        breakpoints={{
                            768: {
                            slidesPerView: 1,
                            },
                        }}
                        >
                                <SwiperSlide>
                                <img src={result1_dog} class="video" alt="결과지 1" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={result2_dog} class="video" alt="결과지 2" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={result3_dog} class="video" alt="결과지 3" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={result4_dog} class="video" alt="결과지 4" />
                                </SwiperSlide>
                        
                    </Swiper>
                </div>
            </div>
        </article>
        <div class="bt_fixed">
              <div class="contents_wrap">
                    <div class="btn_box">
                        <br/>
                        <a href="javascript:;" class="btn" onClick={() => testStart()}><span>테스트 시작하기</span></a>
                    </div>
              </div>
          </div>
    </>
    );
};

export default TestStart ;