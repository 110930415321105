import React from 'react';
import logo2 from '../image/logo2.png';
import dog from '../image/stage-dog2.png';
import cat from '../image/stage-cats2.png';
import { Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import GoogleAdvertise from './GoogleAdvertise';

const Choices = () => {
    const cookies = new Cookies();
    cookies.remove('1-1');
    cookies.remove('1-2');
    cookies.remove('1-3');
    cookies.remove('1-4');
    cookies.remove('1-5');
    cookies.remove('1-6');
    cookies.remove('2-1');
    cookies.remove('2-2');
    cookies.remove('2-3');
    cookies.remove('2-4');
    cookies.remove('3-1');
    cookies.remove('3-2');
    cookies.remove('3-3');
    cookies.remove('3-4');
    cookies.remove('3-5');
    cookies.remove('3-6');
    cookies.remove('4-1');
    cookies.remove('4-2');
    cookies.remove('4-3');
    cookies.remove('4-4');

    return (
        <div class="stageChoice"> 
            <header class="header">
                <div class="pc stage">
                    <div class="h_wrap">
                        <a href="/" class="logo">
                            <img src={logo2} alt="집사의 자격" />
                        </a>
                        <div class="member_box">
                            <div class="member on">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile stage">
                    <a href="/" class="logo">
                        <img src={logo2} alt="집사의 자격" />
                    </a>
                    <a href="/" class="btn_menu close">
                        <div class="blind">Menu</div>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                    </a>
                </div>
            </header>
            <article class="testChoice">
                <div class="contents_wrap">
                    <div class="stage-top" data-aos="fade-up">
                        <h1 class="title" >반려동물을 선택해주세요.</h1>
                    </div>
                    <div class="list"  data-aos="fade-up" data-aos-delay="200">
                        <ul>
                <Link
                to="/stage/cat"
                state={{
                  stage: 1,
                  number: 1,
                }}
                >
                            <a href='/stage/cat'>
                            <li class="pet_l cats" >
                                <span class="tag_name">고양이</span>
                                <span class="tag"><i class='fas fa-angle-down'></i></span>
                            </li>
                            </a>
                </Link>
                            
                        </ul>
        
                        <ul>
                <Link
                to="/stage/dog"
                state={{
                  stage: 1,
                  number: 1,
                }}
                >          
                            <a href='/stage/dog'>
                            <li class="pet_r dogs"> 
                                <span class="tag_name">강아지</span>
                                <span class="tag"><i class='fas fa-angle-down'></i></span>
                            </li>
                            </a>
                </Link>            
                        </ul>
                    </div>
                    <div class="sub">
                        <p class="tit_txt" data-aos="fade-up">입양을 생각하고 있는 반려동물에 맞춰서 테스트를 진행보아요</p>
                    </div>
                    <p class="test_detail">
                        *다른 동물친구들은 다음에 준비해볼게요.
                    </p>
                    <div class="sub">
                        <GoogleAdvertise />
                    </div>
                </div>
            </article>
        </div>
        
    );
};

export default Choices;