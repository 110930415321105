
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const getRanHex = size => {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  
    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)]);
    }  
    return result.join('');
};

function setCsrf()  {

    const whatTime = new Date(Date.now());
    if (!document.cookie && document.cookie === '') {
        const csurf =getRanHex(64);
        cookies.set('csrftoken',csurf);
        cookies.set('X-CSRFToken',csurf);

    }
    else if(whatTime.getMinutes() === 30 && whatTime.getMilliseconds() === 0 ){
        const csurf =getRanHex(64);
        cookies.set('csrftoken',csurf);
        cookies.set('X-CSRFToken',csurf);
    }

    return cookies.get('csrftoken');
    //console.log(cookies.get('csrftoken'));

};


const Header = () => {

    const token  = setCsrf();

    return (
        <Helmet>
                <meta name="csrf-token" content={token} />
        </Helmet>
    );
};
  
export default Header;
  