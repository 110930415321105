import logo2 from '../../image/logo.png';

import $ from "jquery";
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';


const Comment = (props) => {
    let q = props.ask;
    let c = props.choice;
    let new_stage = (props.stage == 1 &&  props.number==6) || (props.stage == 2 &&  props.number==4) || (props.stage == 3 &&  props.number==6) || (props.stage == 4 &&  props.number==4) ? props.stage+1 : props.stage;
    let new_number = (props.stage == 1 &&  props.number==6) || (props.stage == 2 &&  props.number==4) || (props.stage == 3 &&  props.number==6) || (props.stage == 4 &&  props.number==4) ? 1 : props.number + 1;
    //console.log(c[0].txt);

    let ments =  new_stage === 5 && new_number === 1  ? '결과보기' : '다음';

    const [dataReal,setRealData]=useState([]);
    //console.log(c[0].txt);

    const getRealData=()=>{
        fetch("https://zipsa-testing-default-rtdb.firebaseio.com/quest/cat/"+props.stage+"/quest/"+props.number+".json"
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            return response.json();
          })
          .then(function(myJson) {
            setRealData(myJson['real'])
          });
      }

    useEffect(()=>{
        getRealData();
        $('.prograss span').each(function(index, item){ 
            if($(item).attr('id')==(props.stage+'-'+props.number)){
                $(item).addClass('on');
            }
        });
        $('.scroll_down').on('click',function(){
            $('.scroll_down').css("display","none");
            $(".etc").slideDown(function(){
                $('.scroll_up').css("display","block");
            });
            
        });
        $('.scroll_up').on('click',function(){
            $('.scroll_up').css("display","none");
            $(".etc").slideUp(function(){
                $('.scroll_down').css("display","block");
            });
        });

        if(props.stage === 2){
            $('.page-title').html((props.number+6)+'. 정답 / 간단 해설');
        }
        if(props.stage === 3){
            $('.page-title').html((props.number+10)+'. 정답 / 간단 해설');
        }
        if(props.stage === 4){
            $('.page-title').html((props.number+16)+'. 정답 / 간단 해설');
        }
        if(props.stage === 1){
            $('.page-title').html(props.number+'. 정답 / 간단 해설');
        }
        if(new_stage === 5 && new_number === 1){
            $('.btn').css('background-color','#E98764');
            $('.btn').css('color','#fff');
            $('.btn').css('width','15vh');
        }

      }, []);

    return(
        <>
            <header class="header">
            <div class="pc">
                <div class="h_wrap">
                    <a href="/" class="logo">
                        <img src={logo2} alt="집사의 자격" />
                    </a>
                    <div class="member_box">
                        <div class="member on">
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile">
                <a href="/" class="logo">
                    <img src={logo2} alt="집사의 자격" />
                </a>
                <a href="/" class="btn_menu close">
                    <div class="blind">Menu</div>
                        <span> </span>
                        <span> </span>
                        <span> </span>
                </a>
            </div>
        </header>
        <div class="stage-title">{props.title}</div>
        <div class="prograss">
                <span id="1-1"></span>
                <span id="1-2"></span>
                <span id="1-3"></span>
                <span id="1-4"></span>
                <span id="1-5"></span>
                <span id="1-6"></span>
                <span id="2-1"></span>
                <span id="2-2"></span>
                <span id="2-3"></span>
                <span id="2-4"></span>
                <span id="3-1"></span>
                <span id="3-2"></span>
                <span id="3-3"></span>
                <span id="3-4"></span>
                <span id="3-5"></span>
                <span id="3-6"></span>
                <span id="4-1"></span>
                <span id="4-2"></span>
                <span id="4-3"></span>
                <span id="4-4"></span>  
        </div>
        <br/>
        <article class="testCat">
            <div class="contents_wrap">
                <div class="stage-top" data-aos="fade-up">
                    <div class="page-title"></div>
                </div>
                <div class="panel"  data-aos="fade-up" data-aos-delay="200">
                    <div class="title">
                        <span class="result-cmt" dangerouslySetInnerHTML={{__html:dataReal}}></span>
                    </div>
                    <div class="layer">
                        <img src={ require('../../image/'+props.stage+'-'+props.number+'.png')}></img>
                    </div>
                </div>
                <div class="panel"  data-aos="fade-up" data-aos-delay="200">
                    <div class="result"  dangerouslySetInnerHTML={{__html:props.cmt}}></div>
                </div>
                <div class="btn_box_r">

            <Link
                to="/stage/cat"
                state={{
                  stage: new_stage,
                  number: new_number,
                }}
            >
                        <a href='javascript:;' class="btn"  data-aos="fade-up" data-aos-delay="200">{ments}</a>
              </Link>
                </div>
            </div>
        </article>
        </>
    )
}
export default Comment;