
const Footer = () => {
    return (
        <>
        <section>
            <footer class="footer">
                <div class="f_wrap">
                    {/*<div class="fnb_box">
                        <div class="partner">
                            <a href="javascript:;" class="btn_site">제휴사이트</a>
                            <ul class="site_list">
                                <li><a href="javascript:;">(준비중 & 모집중)</a></li>
                            </ul>
                        </div>
                        <ul class="fnb">
                            <li><a href="javascript:;">집사의자격</a></li>
                            <li><a href="javascript:;">프로젝트소개</a></li>
                        </ul>
                    </div>*/}
                    <div class="info_box">
                        <div class="info">
                            <p><span>서비스기획자 : 박지원</span><span>개발자: 손향지</span></p>
                            <p><span>E-Mail: zipsa2auth@gmail.com</span><span><a href="https://www.swygbro.com/" taget="_blank">Prod By. SWYG</a></span></p>
                        </div>
                        <p class="copyright">
                            <span>Copyright(C) 집사의 자격</span>
                            <span>All Rights Reserved.</span>
                        </p>
                        <a href="javascript:;" class="btn_scroll_top"><span class="blind">상단으로 이동</span></a>
                    </div>
                </div>
            </footer>
        </section>
        </>
    );
};
export default Footer ;