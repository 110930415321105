import React,{useState,useEffect} from 'react';
import { useLocation } from "react-router-dom"; // 추가된 부분
import Option from './Option';
import Intro from './Intro';
import Loading from './Loading';


const CatQuest = () => {
    const location = useLocation(); // 추가된 부분
    const stage = location.state?.stage; // 추가된 부분
    const number = location.state?.number; // 추가된 부분

    if(stage===5&&number===1){
        window.location.href='/stage/dog/result';
    }

    const [isOpen, setShow] = useState(false);
    const [dataQ,setQData]=useState([]);
    const [dataA,setAData]=useState([]);
    const [title,setTitle]=useState([]);

    useEffect(()=>{
        //console.log("stage"+stage);
        //console.log("number"+number);
        let timer = setTimeout(()=>{ setShow(true); }, 1000);
    });

    const getTitle=()=>{
        fetch("https://zipsa-testing-default-rtdb.firebaseio.com/quest/dog/"+stage+"/title.json"
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            return response.json();
          })
          .then(function(myJson) {
            setTitle(myJson)
          });
      }
    const getQData=()=>{
        fetch("https://zipsa-testing-default-rtdb.firebaseio.com/quest/dog/"+stage+"/quest/"+number+".json"
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            return response.json();
          })
          .then(function(myJson) {
            setQData(myJson['ask'])
          });
      }
      const getAData=()=>{
        fetch("https://zipsa-testing-default-rtdb.firebaseio.com/quest/dog/"+stage+"/answer/"+number+".json"
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            return response.json();
          })
          .then(function(myJson) {
            //console.log(myJson.slice(1,4));
            setAData(myJson.slice(1,4))
          });
      }
      useEffect(()=>{
        window.scrollTo(0, 0);
        getAData();
        getQData();
        getTitle();
      },[])
    
    return(
    <div class="stageDog"> 
        {
            isOpen === false && number === 1
            ? 
                stage ===5 ? (
                    <Loading prop={''}/> 
                )
                :(
                    <Intro prop={title} /> 
                )
               
            : (
                <Option ask={dataQ} choice={dataA} stage={stage} number={number} title={title}/>
            )

        }
         </div>
    )
}

export default CatQuest;