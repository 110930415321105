import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
//<Provider store={store}></Provider>

    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  ,
  document.getElementById('root'),
);
