import $ from "jquery";
import React,{useState,useEffect} from 'react';
const Check = (prop) =>{

    useEffect(()=>{
        if(prop.score == 1){
            $("#ox").text('O');
            $("#ox_ko").text('정답');
        }
        else{
            $("#ox").text('X');
            $("#ox_ko").text('오답');
        }
      }, []);

    return(
        <div class="intro">
            <div class="ment">
                <h1 id="ox"></h1>
                <h1 id="ox_ko"></h1>
            </div>
        </div>
    )
}

export default Check;