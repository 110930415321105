import React from 'react';
import cat from "../../image/stage-cats2.png";
const Intro = ({prop}) =>{

    return(
        <div class="intro">
            <p class="sound">냐~옹</p>
            <br/>
            <div class="icon">
                <img src={cat}/>
            </div>
            <br/>
            <h2 class="stage-name">{prop}</h2>
        </div>
    )
}

export default Intro;