import React from 'react';
import dog from '../../image/stage-dog2.png';
const Intro = ({prop}) =>{

    return(
        <div class="intro">
            <p class="sound">멍!멍!</p>
            <br/>
            <div class="icon">
                <img src={dog}/>
            </div>
            <br/>
            <h2 class="stage-name">{prop}</h2>
        </div>
    )
}

export default Intro;