import $ from "jquery";
import img from "../../image/pointer2.png";
import book1 from "../../image/recommed1.png";
import book2 from "../../image/recommed2.png";
import book3 from "../../image/recommed3.png";
import book4 from "../../image/recommed4.png";
import book5 from "../../image/recommed5.png";
import video1 from "../../image/video1.png";
import video2 from "../../image/video2.png";
import video3 from "../../image/video3.png";

import adopt1 from "../../image/adopt1.png";
import adopt2 from "../../image/adopt2.png";
import adopt3 from "../../image/adopt3.png";

import kakao from "../../image/kakaotalk.png";
import fb from "../../image/facebook.png";
import insta from "../../image/instagram.png";
import logo2 from '../../image/logo2.png';
import tw from "../../image/twitter.png";
import link from "../../image/link.png";
import result1 from "../../image/result1_2023.png";
import result2 from "../../image/result2_2023.png";
import result3 from "../../image/result3_2023.png";
import result4 from "../../image/result4_2023.png";
import share_thumbnail from "../../image/share_thumbnail.png";

import React,{useState,useEffect} from 'react';
import { Swiper, SwiperSlide } from "swiper/react"; // basic
import "swiper/css"; //basic
import "swiper/css/navigation";
import "swiper/css/pagination";
import ApexCharts from 'apexcharts'
import { firebaseDB ,firebaseAuth} from '../../DB';
import { collection, getDocs, doc,addDoc,updateDoc } from 'firebase/firestore'
import { Cookies } from 'react-cookie';
import GoogleAdvertise from '../GoogleAdvertise';


const Result = () =>{
    const cookies = new Cookies();
    const [isOpen, setShow] = useState(false);
    const total = 
    parseInt(cookies.get('1-1'))+
    parseInt(cookies.get('1-2'))+
    parseInt(cookies.get('1-3'))+
    parseInt(cookies.get('1-4'))+
    parseInt(cookies.get('1-5'))+
    parseInt(cookies.get('1-6'))+
    parseInt(cookies.get('2-1'))+
    parseInt(cookies.get('2-2'))+
    parseInt(cookies.get('2-3'))+
    parseInt(cookies.get('2-4'))+
    parseInt(cookies.get('3-1'))+
    parseInt(cookies.get('3-2'))+
    parseInt(cookies.get('3-3'))+
    parseInt(cookies.get('3-4'))+
    parseInt(cookies.get('3-5'))+
    parseInt(cookies.get('3-6'))+
    parseInt(cookies.get('4-1'))+
    parseInt(cookies.get('4-2'))+
    parseInt(cookies.get('4-3'))+
    parseInt(cookies.get('4-4'))

    //console.log("total="+total);


    const copy = () => {
        var tempElem = document.createElement('textarea');
        tempElem.value = 'https://zipsa.swygbro.com';  
        document.body.appendChild(tempElem);

        tempElem.select();
        document.execCommand("copy!!");
        document.body.removeChild(tempElem);
        alert('링크가 복사되었습니다!');
    }

    const sendSns = (sns, url, txt) =>
    {
        var o;
        var _url = encodeURIComponent(url);
        var _txt = encodeURIComponent(txt);
        var _br  = encodeURIComponent('\r\n');
        // console.log(_url);
        // console.log(_txt);
        // console.log(sns);
    
        switch(sns)
        {
            case 'facebook':
                o = {
                    method:'popup',
                    url:'http://www.facebook.com/sharer/sharer.php?u=' + _url + '&t=' + _txt
                };
                break;
    
            case 'twitter':
                o = {
                    method:'popup',
                    url:'http://twitter.com/intent/tweet?text=' + _txt + '&url=' + _url
                };
                break;
    
            default:
                alert('지원하지 않는 SNS입니다.');
                return false;
        }
    
        switch(o.method)
        {
            case 'popup':
                window.open(o.url);
                break;
        }
    }

    const shareKakao = () => {
        window.Kakao.init(process.env.REACT_APP_KAKAO_KEY);
       
        window.Kakao.Link.sendDefault({
            objectType:"feed",
            content:{
                title:"집사의 자격",
                description:"반려동물을 맞이할 예비 집사들을 위한 경제, 수명, 거주, 산책 등 '현재 나는 집사가 될 준비가 되었는지' 집사력 테스트!",
                imageUrl:share_thumbnail,      
                link:{
                    webUrl:"https://zipsa.swygbro.com",
                    mobileWebUrl:"https://zipsa.swygbro.com",
                }
            },
            buttons: [
                {
                  title: "테스트 하러가기",
                  link: {
                    mobileWebUrl: "https://zipsa.swygbro.com",
                  },
                },
            ],
        });
    }
    const [resultData,setResultData]=useState([]);

    const docRef = async (key,dataReal) =>{
        var num =  parseInt(dataReal)+1
        //console.log(typeof num)
        //console.log(typeof dataReal)
        if(key =='first'){
            await updateDoc(doc(firebaseDB, 'cat-result','oTzU3MKY37mLyxgdDiQh'), {
                first: num,
            });
        }
        if(key =='second'){
            await updateDoc(doc(firebaseDB, 'cat-result','oTzU3MKY37mLyxgdDiQh'), {
                second: num,
            });
        }
        if(key =='third'){
            await updateDoc(doc(firebaseDB, 'cat-result','oTzU3MKY37mLyxgdDiQh'), {
                third: num,
            });
        }
        if(key =='fourth'){
            await updateDoc(doc(firebaseDB, 'cat-result','oTzU3MKY37mLyxgdDiQh'), {
                fourth: num,
            });
        }
        
    };

    const getData = async () =>{
        const query = await getDocs(collection(firebaseDB, 'cat-result'));
        query.forEach((doc) => {
            if (total > 16 ){
                docRef('fourth',doc.data().fourth);
            }
            else if( total <=16 && total >12  ){
                docRef('third',doc.data().third);
            }
            else if( total <=12 && total > 6  ){
                docRef('second',doc.data().second);
            }
            else{
                docRef('first',doc.data().first);
            }
            setResultData([doc.data().first,doc.data().second, doc.data().third,doc.data().fourth, ...resultData]);

            var options = {
                "chart": {
                    "animations": {
                        "enabled": true,
                        "easing": "swing"
                    },
                    "background": "#fff",
                    "dropShadow": {
                        "blur": 3
                    },
                    "foreColor": "#373D3F",
                    "fontFamily": "Work Sans",
                    "height": 135,
                    "id": "2ElN9",
                    "stacked": true,
                    "stackType": "100%",
                    "toolbar": {
                        "show": false
                    },
                    "type": "bar",
                    "max-width": 375,
                    "min-width": 343,
                    "zoom": {
                        "enabled": true
                    },
                    "fontUrl": null
                },
                "plotOptions": {
                    "bar": {
                        "horizontal": true,
                        "barHeight": "100%",
                        "borderRadius": 8,
                        "dataLabels": {
                            "position": "center"
                        }
                    },
                    "radialBar": {
                        "hollow": {
                            "background": "#fff"
                        },
                        "dataLabels": {
                            "name": {},
                            "value": {},
                            "total": {}
                        }
                    },
                    "pie": {
                        "donut": {
                            "labels": {
                                "name": {},
                                "value": {},
                                "total": {}
                            }
                        }
                    }
                },
                "colors": [
                    "#FFDACD",
                    "#F0B089",
                    "#B1674D",
                    "#664323",
                    "#546E7A",
                    "#FEF8F1",
                    "#FFEDE2",
                    "#F0B089",
                    "#E98764",
                    "#B1674D",
                    "#664323"
                ],
                "dataLabels": {
                    "style": {
                        "fontSize": 14,
                        "fontWeight": 700,
                        "colors": [
                            "#FFFFFF"
                        ]
                    },
                    "background": {
                        "enabled": false
                    }
                },
                "fill": {},
                "grid": {
                    "padding": {
                        "right": 25,
                        "left": 15
                    }
                },
                "legend": {
                    "horizontalAlign": "right",
                    "fontSize": 8,
                    "fontWeight": 600,
                    "offsetX": 1,
                    "offsetY": 0,
                    "markers": {
                        "width": 10,
                        "height": 10,
                        "radius": 10,
                        "shape": "square",
                        "size": 8
                    },
                    "itemMargin": {
                        "vertical": 0
                    }
                },
                "series": [
                    {
                        "name": "Level 1",
                        "data": [
                            {
                                "x": "",
                                "y": doc.data().first,
                            }
                        ]
                    },
                    {
                        "name": "Level 2",
                        "data": [
                            {
                                "x": "",
                                "y": doc.data().second
                            }
                        ]
                    },
                    {
                        "name": "Level 3",
                        "data": [
                            {
                                "x": "",
                                "y": doc.data().third
                            }
                        ]
                    },
                    {
                        "name": "Level 4",
                        "data": [
                            {
                                "x": "",
                                "y": doc.data().fourth
                            }
                        ]
                    }
                ],
                "tooltip": {
                    "shared": false,
                    "intersect": true
                },
                "xaxis": {
                    "offsetY": -17,
                    "labels": {
                        "trim": true,
                        "style": {}
                    },
                    "tickPlacement": "between",
                    "title": {
                        "style": {
                            "fontWeight": 700
                        }
                    },
                    "tooltip": {
                        "enabled": false
                    }
                },
                "yaxis": {
                    "tickAmount": 5,
                    "max": 100,
                    "min": 0,
                    "labels": {
                        "style": {}
                    },
                    "title": {
                        "style": {
                            "fontWeight": 700
                        }
                    }
                },
                "theme": {
                    "palette": "palette4"
                },
                "_chartInstances": [
                    {
                        "id": "2ElN9",
                        "chart": {
                            "opts": {
                                "series": [
                                    {
                                        "name": "Level 1"
                                    },
                                    {
                                        "name": "Level 2"
                                    },
                                    {
                                        "name": "Level 3"
                                    },
                                    {
                                        "name": "Level 4"
                                    }
                                ],
                                "annotations": {
                                    "position": "front",
                                    "yaxis": [],
                                    "xaxis": [],
                                    "points": []
                                },
                                "chart": {
                                    "type": "bar",
                                    "background": "",
                                    "foreColor": "#333",
                                    "offsetX": 0,
                                    "offsetY": 0,
                                    "toolbar": {
                                        "show": false
                                    },
                                    "animations": {
                                        "enabled": false
                                    },
                                    "dropShadow": {
                                        "enabled": false,
                                        "top": 2,
                                        "left": 2,
                                        "blur": 4,
                                        "color": "#000",
                                        "opacity": 0.35
                                    },
                                    "fontFamily": "Roboto",
                                    "height": 230,
                                    "width": 400,
                                    "stacked": true,
                                    "stackType": "100%",
                                    "id": "2ElN9"
                                },
                                "plotOptions": {
                                    "bar": {
                                        "horizontal": true,
                                        "columnWidth": "70%",
                                        "barHeight": "70%",
                                        "distributed": false,
                                        "borderRadius": 10,
                                        "colors": {
                                            "ranges": [],
                                            "backgroundBarColors": [],
                                            "backgroundBarOpacity": 1
                                        },
                                        "dataLabels": {
                                            "position": "center"
                                        }
                                    },
                                    "heatmap": {
                                        "radius": 2,
                                        "enableShades": true,
                                        "shadeIntensity": 0.5
                                    },
                                    "radialBar": {
                                        "startAngle": 0,
                                        "endAngle": 360,
                                        "offsetX": 0,
                                        "offsetY": 0,
                                        "hollow": {
                                            "margin": 5,
                                            "size": "50%",
                                            "background": "#fff",
                                            "position": "front",
                                            "dropShadow": {
                                                "enabled": false,
                                                "top": 0,
                                                "left": 0,
                                                "blur": 3,
                                                "color": "#000",
                                                "opacity": 0.5
                                            }
                                        },
                                        "track": {
                                            "show": true,
                                            "background": "#f2f2f2",
                                            "strokeWidth": "97%",
                                            "opacity": 1,
                                            "margin": 5,
                                            "dropShadow": {
                                                "enabled": false,
                                                "top": 0,
                                                "left": 0,
                                                "blur": 3,
                                                "color": "#000",
                                                "opacity": 0.5
                                            }
                                        },
                                        "dataLabels": {
                                            "show": true,
                                            "name": {
                                                "show": true,
                                                "fontSize": 16,
                                                "offsetY": 0
                                            },
                                            "value": {
                                                "show": true,
                                                "fontSize": 14,
                                                "offsetY": 16
                                            },
                                            "total": {
                                                "show": false,
                                                "label": "Total",
                                                "fontSize": 16
                                            }
                                        }
                                    },
                                    "pie": {
                                        "offsetX": 0,
                                        "offsetY": 0,
                                        "dataLabels": {
                                            "offset": 0
                                        },
                                        "donut": {
                                            "size": "65%",
                                            "labels": {
                                                "show": false,
                                                "name": {
                                                    "show": true,
                                                    "fontSize": 16,
                                                    "offsetY": -10
                                                },
                                                "value": {
                                                    "show": true,
                                                    "fontSize": 20,
                                                    "offsetY": 10
                                                },
                                                "total": {
                                                    "show": false,
                                                    "showAlways": false,
                                                    "label": "Total",
                                                    "fontSize": 16
                                                }
                                            }
                                        }
                                    },
                                    "radar": {
                                        "offsetX": 0,
                                        "offsetY": 0,
                                        "polygons": {
                                            "strokeColors": "#e8e8e8",
                                            "connectorColors": "#e8e8e8",
                                            "fill": {}
                                        }
                                    }
                                },
                                "theme": {
                                    "mode": "light",
                                    "palette": "palette4"
                                },
                                "dataLabels": {
                                    "enabled": true,
                                    "textAnchor": "middle",
                                    "offsetX": 0,
                                    "offsetY": 0,
                                    "style": {
                                        "fontSize": 12,
                                        "fontWeight": 700
                                    },
                                    "background": {
                                        "enabled": false,
                                        "foreColor": "#fff",
                                        "borderRadius": 2,
                                        "padding": 4,
                                        "opacity": 0.9,
                                        "borderWidth": 1,
                                        "borderColor": "#fff"
                                    },
                                    "dropShadow": {
                                        "enabled": false,
                                        "top": 1,
                                        "left": 1,
                                        "blur": 1,
                                        "color": "#000",
                                        "opacity": 0.45
                                    }
                                },
                                "markers": {
                                    "size": 0,
                                    "strokeColors": "#fff",
                                    "strokeWidth": 2,
                                    "strokeOpacity": 0.9,
                                    "strokeDashArray": 0,
                                    "fillOpacity": 1,
                                    "shape": "circle",
                                    "radius": 2,
                                    "offsetX": 0,
                                    "offsetY": 0,
                                    "hover": {}
                                },
                                "xaxis": {
                                    "type": "category",
                                    "offsetX": 0,
                                    "offsetY": 0,
                                    "position": "bottom",
                                    "labels": {
                                        "show": true,
                                        "rotate": -45,
                                        "rotateAlways": false,
                                        "trim": true,
                                        "offsetX": 0,
                                        "offsetY": 0,
                                        "style": {
                                            "fontSize": 12,
                                            "fontWeight": 400
                                        }
                                    },
                                    "axisBorder": {
                                        "show": true,
                                        "color": "#e0e0e0"
                                    },
                                    "axisTicks": {
                                        "show": true,
                                        "color": "#e0e0e0"
                                    },
                                    "title": {
                                        "style": {
                                            "fontSize": 12,
                                            "fontWeight": 700
                                        }
                                    },
                                    "crosshairs": {
                                        "show": true,
                                        "width": 1,
                                        "position": "back",
                                        "opacity": 0.9,
                                        "stroke": {
                                            "color": "#b6b6b6",
                                            "width": 1
                                        },
                                        "fill": {
                                            "type": "solid",
                                            "color": "#B1B9C4",
                                            "gradient": {
                                                "colorFrom": "#D8E3F0",
                                                "colorTo": "#BED1E6",
                                                "opacityFrom": 0.4,
                                                "opacityTo": 0.5
                                            }
                                        },
                                        "dropShadow": {
                                            "enabled": false,
                                            "left": 0,
                                            "top": 0,
                                            "blur": 1,
                                            "opacity": 0.4
                                        }
                                    },
                                    "convertedCatToNumeric": false
                                },
                                "yaxis": [
                                    {
                                        "show": true,
                                        "showAlways": false,
                                        "showForNullSeries": true,
                                        "opposite": false,
                                        "reversed": false,
                                        "logarithmic": false,
                                        "logBase": 10,
                                        "tickAmount": 5,
                                        "forceNiceScale": false,
                                        "max": 100,
                                        "min": 0,
                                        "floating": false,
                                        "labels": {
                                            "show": true,
                                            "minWidth": 0,
                                            "maxWidth": 160,
                                            "offsetX": 0,
                                            "offsetY": 0,
                                            "rotate": 0,
                                            "padding": 20,
                                            "style": {
                                                "fontSize": 11,
                                                "fontWeight": 400,
                                                "cssClass": ""
                                            }
                                        },
                                        "axisBorder": {
                                            "show": false,
                                            "color": "#e0e0e0",
                                            "width": 1,
                                            "offsetX": 0,
                                            "offsetY": 0
                                        },
                                        "axisTicks": {
                                            "show": false,
                                            "color": "#e0e0e0",
                                            "width": 6,
                                            "offsetX": 0,
                                            "offsetY": 0
                                        },
                                        "title": {
                                            "rotate": -90,
                                            "offsetY": 0,
                                            "offsetX": 0,
                                            "style": {
                                                "fontSize": 11,
                                                "fontWeight": 700,
                                                "cssClass": ""
                                            }
                                        },
                                        "tooltip": {
                                            "enabled": false,
                                            "offsetX": 0
                                        },
                                        "crosshairs": {
                                            "show": true,
                                            "position": "front",
                                            "stroke": {
                                                "color": "#b6b6b6",
                                                "width": 1,
                                                "dashArray": 0
                                            }
                                        }
                                    }
                                ],
                                "grid": {
                                    "show": true,
                                    "borderColor": "#e0e0e0",
                                    "strokeDashArray": 0,
                                    "position": "back",
                                    "xaxis": {
                                        "lines": {
                                            "show": false
                                        }
                                    },
                                    "yaxis": {
                                        "lines": {
                                            "show": true
                                        }
                                    },
                                    "row": {
                                        "opacity": 0.5
                                    },
                                    "column": {
                                        "opacity": 0.5
                                    },
                                    "padding": {
                                        "top": 0,
                                        "right": 25,
                                        "bottom": 0,
                                        "left": 15
                                    }
                                },
                                "stroke": {
                                    "show": true,
                                    "curve": "smooth",
                                    "lineCap": "butt",
                                    "width": 2,
                                    "dashArray": 0
                                },
                                "fill": {
                                    "type": "solid",
                                    "opacity": 0.85,
                                    "gradient": {
                                        "shade": "dark",
                                        "type": "horizontal",
                                        "shadeIntensity": 0.5,
                                        "inverseColors": true,
                                        "opacityFrom": 1,
                                        "opacityTo": 1,
                                        "stops": [
                                            0,
                                            50,
                                            100
                                        ],
                                        "colorStops": []
                                    },
                                    "pattern": {
                                        "style": "squares",
                                        "width": 6,
                                        "height": 6,
                                        "strokeWidth": 2
                                    }
                                },
                                "legend": {
                                    "show": true,
                                    "showForSingleSeries": false,
                                    "floating": false,
                                    "position": "bottom",
                                    "horizontalAlign": "center",
                                    "fontSize": 14,
                                    "fontWeight": 400,
                                    "offsetX": -20,
                                    "offsetY": 0,
                                    "labels": {
                                        "useSeriesColors": false
                                    },
                                    "markers": {
                                        "width": 12,
                                        "height": 12,
                                        "strokeWidth": 0,
                                        "strokeColor": "#fff",
                                        "radius": 12
                                    },
                                    "itemMargin": {
                                        "horizontal": 5,
                                        "vertical": 0
                                    },
                                    "onItemClick": {
                                        "toggleDataSeries": true
                                    },
                                    "onItemHover": {
                                        "highlightDataSeries": true
                                    }
                                }
                            },
                            "w": {
                                "globals": {
                                    "chartID": "2ElN9",
                                    "cuid": "nobl5ufv",
                                    "events": {
                                        "beforeMount": [],
                                        "mounted": [],
                                        "updated": [],
                                        "clicked": [],
                                        "selection": [],
                                        "dataPointSelection": [],
                                        "zoomed": [],
                                        "scrolled": []
                                    },
                                    "clientX": 1323,
                                    "clientY": 508,
                                    "fill": {
                                        "colors": [
                                            "#FFDACD",
                                            "#F0B089",
                                            "#B1674D",
                                            "#664323",
                                            "#546E7A",
                                            "#FEF8F1",
                                            "#FFEDE2",
                                            "#F0B089",
                                            "#E98764",
                                            "#B1674D",
                                            "#664323"
                                        ]
                                    },
                                    "stroke": {},
                                    "dataLabels": {
                                        "style": {
                                            "colors": [
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF",
                                                "#FFFFFF"
                                            ]
                                        }
                                    },
                                    "radarPolygons": {
                                        "fill": {
                                            "colors": [
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none",
                                                "none"
                                            ]
                                        }
                                    },
                                    "markers": {
                                        "size": [
                                            0,
                                            0,
                                            0,
                                            0,
                                            0
                                        ],
                                        "largestSize": 0
                                    },
                                    "animationEnded": true,
                                    "isDirty": true,
                                    "isExecCalled": false,
                                    "initialConfig": {
                                        "annotations": {
                                            "position": "front",
                                            "texts": [],
                                            "images": [],
                                            "shapes": []
                                        },
                                        "forecastDataPoints": {
                                            "count": 0,
                                            "fillOpacity": 0.5,
                                            "dashArray": 4
                                        },
                                        "labels": [],
                                        "markers": {
                                            "discrete": [],
                                            "size": 0,
                                            "strokeColors": "#fff",
                                            "strokeWidth": 2,
                                            "strokeOpacity": 0.9,
                                            "strokeDashArray": 0,
                                            "fillOpacity": 1,
                                            "shape": "circle",
                                            "width": 8,
                                            "height": 8,
                                            "radius": 2,
                                            "offsetX": 0,
                                            "offsetY": 0,
                                            "showNullDataPoints": true,
                                            "hover": {
                                                "sizeOffset": 3
                                            }
                                        },
                                        "noData": {
                                            "align": "center",
                                            "verticalAlign": "middle",
                                            "offsetX": 0,
                                            "offsetY": 0,
                                            "style": {
                                                "fontSize": "14px"
                                            }
                                        },
                                        "responsive": [],
                                        "states": {
                                            "normal": {
                                                "filter": {
                                                    "type": "none",
                                                    "value": 0
                                                }
                                            },
                                            "hover": {
                                                "filter": {
                                                    "type": "lighten",
                                                    "value": 0.1
                                                }
                                            },
                                            "active": {
                                                "allowMultipleDataPointsSelection": false,
                                                "filter": {
                                                    "type": "darken",
                                                    "value": 0.5
                                                }
                                            }
                                        },
                                        "title": {
                                            "align": "left",
                                            "margin": 5,
                                            "offsetX": 0,
                                            "offsetY": 0,
                                            "floating": false,
                                            "style": {
                                                "fontSize": "14px",
                                                "fontWeight": 900
                                            }
                                        },
                                        "subtitle": {
                                            "align": "left",
                                            "margin": 5,
                                            "offsetX": 0,
                                            "offsetY": 30,
                                            "floating": false,
                                            "style": {
                                                "fontSize": "12px",
                                                "fontWeight": 400
                                            }
                                        },
                                        "stroke": {
                                            "show": true,
                                            "curve": "smooth",
                                            "lineCap": "butt",
                                            "width": 2,
                                            "dashArray": 0
                                        },
                                        "yaxis": [
                                            null
                                        ]
                                    },
                                    "initialSeries": [
                                        {
                                            "name": "Level 1",
                                            "data": [
                                                {
                                                    "x": "",
                                                    "y": doc.data().first
                                                }
                                            ]
                                        },
                                        {
                                            "name": "Level 2",
                                            "data": [
                                                {
                                                    "x": "",
                                                    "y": doc.data().second
                                                }
                                            ]
                                        },
                                        {
                                            "name": "Level 3",
                                            "data": [
                                                {
                                                    "x": "",
                                                    "y": doc.data().third
                                                }
                                            ]
                                        },
                                        {
                                            "name": "Level 4",
                                            "data": [
                                                {
                                                    "x": "",
                                                    "y": doc.data().fourth
                                                }
                                            ]
                                        }
                                    ],
                                    "lastXAxis": [],
                                    "lastYAxis": [],
                                    "columnSeries": null,
                                    "labels": [
                                        ""
                                    ],
                                    "timescaleLabels": [],
                                    "noLabelsProvided": false,
                                    "allSeriesCollapsed": false,
                                    "collapsedSeries": [],
                                    "collapsedSeriesIndices": [],
                                    "ancillaryCollapsedSeries": [],
                                    "ancillaryCollapsedSeriesIndices": [],
                                    "risingSeries": [],
                                    "dataFormatXNumeric": false,
                                    "capturedSeriesIndex": -1,
                                    "capturedDataPointIndex": -1,
                                    "selectedDataPoints": [],
                                    "goldenPadding": 35,
                                    "invalidLogScale": false,
                                    "ignoreYAxisIndexes": [],
                                    "yAxisSameScaleIndices": [],
                                    "maxValsInArrayIndex": 0,
                                    "radialSize": 17.60878048780488,
                                    "zoomEnabled": false,
                                    "panEnabled": false,
                                    "selectionEnabled": false,
                                    "yaxis": null,
                                    "mousedown": false,
                                    "lastClientPosition": {},
                                    "yValueDecimal": 0,
                                    "total": 0,
                                    "SVGNS": "http://www.w3.org/2000/svg",
                                    "svgWidth": 331,
                                    "svgHeight": 133,
                                    "noData": false,
                                    "locale": {
                                        "months": [
                                            "January",
                                            "February",
                                            "March",
                                            "April",
                                            "May",
                                            "June",
                                            "July",
                                            "August",
                                            "September",
                                            "October",
                                            "November",
                                            "December"
                                        ],
                                        "shortMonths": [
                                            "Jan",
                                            "Feb",
                                            "Mar",
                                            "Apr",
                                            "May",
                                            "Jun",
                                            "Jul",
                                            "Aug",
                                            "Sep",
                                            "Oct",
                                            "Nov",
                                            "Dec"
                                        ],
                                        "days": [
                                            "Sunday",
                                            "Monday",
                                            "Tuesday",
                                            "Wednesday",
                                            "Thursday",
                                            "Friday",
                                            "Saturday"
                                        ],
                                        "shortDays": [
                                            "Sun",
                                            "Mon",
                                            "Tue",
                                            "Wed",
                                            "Thu",
                                            "Fri",
                                            "Sat"
                                        ],
                                        "toolbar": {
                                            "exportToSVG": "Download SVG",
                                            "exportToPNG": "Download PNG",
                                            "exportToCSV": "Download CSV",
                                            "menu": "Menu",
                                            "selection": "Selection",
                                            "selectionZoom": "Selection Zoom",
                                            "zoomIn": "Zoom In",
                                            "zoomOut": "Zoom Out",
                                            "pan": "Panning",
                                            "reset": "Reset Zoom"
                                        }
                                    },
                                    "dom": {
                                        "baseEl": {
                                            "_prevClass": "2ElN9"
                                        },
                                        "elWrap": {},
                                        "Paper": {
                                            "_stroke": "#000000",
                                            "_event": null,
                                            "dom": {},
                                            "node": {},
                                            "type": "svg",
                                            "_defs": {
                                                "_stroke": "#000000",
                                                "_event": null,
                                                "dom": {},
                                                "node": {},
                                                "type": "defs"
                                            }
                                        },
                                        "elGraphical": {
                                            "_stroke": "#000000",
                                            "_event": null,
                                            "dom": {},
                                            "node": {},
                                            "type": "g"
                                        },
                                        "elAnnotations": {
                                            "_stroke": "#000000",
                                            "_event": null,
                                            "dom": {},
                                            "node": {},
                                            "type": "g"
                                        },
                                        "elLegendWrap": {},
                                        "elLegendForeign": {},
                                        "elGridRectMask": {},
                                        "elGridRectMarkerMask": {},
                                        "elForecastMask": {},
                                        "elNonForecastMask": {},
                                        "elGridRect": {
                                            "_stroke": "none",
                                            "_event": null,
                                            "dom": {},
                                            "node": {},
                                            "type": "rect"
                                        },
                                        "elGridRectMarker": {
                                            "_stroke": "none",
                                            "_event": null,
                                            "dom": {},
                                            "node": {},
                                            "type": "rect"
                                        }
                                    },
                                    "memory": {
                                        "methodsToExec": []
                                    },
                                    "shouldAnimate": true,
                                    "skipLastTimelinelabel": false,
                                    "skipFirstTimelinelabel": false,
                                    "delayedElements": [
                                        {
                                            "el": {
                                                "instance": {
                                                    "_stroke": "#000000",
                                                    "_event": null,
                                                    "dom": {},
                                                    "type": "g"
                                                }
                                            },
                                            "index": 0
                                        },
                                        {
                                            "el": {
                                                "instance": {
                                                    "_stroke": "#000000",
                                                    "_event": null,
                                                    "dom": {},
                                                    "type": "g"
                                                }
                                            },
                                            "index": 0
                                        },
                                        {
                                            "el": {
                                                "instance": {
                                                    "_stroke": "#000000",
                                                    "_event": null,
                                                    "dom": {},
                                                    "type": "g"
                                                }
                                            },
                                            "index": 0
                                        }
                                    ],
                                    "axisCharts": true,
                                    "isDataXYZ": false,
                                    "resized": true,
                                    "resizeTimer": null,
                                    "comboCharts": false,
                                    "dataChanged": true,
                                    "previousPaths": [
                                        {
                                            "type": "bar",
                                            "paths": [
                                                {
                                                    "d": "M 0.1 0L 41.5 0Q 41.5 0 41.5 0L 41.5 44.348Q 41.5 44.348 41.5 44.348L 41.5 44.348L 0.1 44.348L 0.1 44.348z"
                                                }
                                            ],
                                            "realIndex": "0"
                                        },
                                        {
                                            "type": "bar",
                                            "paths": [
                                                {
                                                    "d": "M 41.5 0L 110.5 0Q 110.5 0 110.5 0L 110.5 44.348Q 110.5 44.348 110.5 44.348L 110.5 44.348L 41.5 44.348L 41.5 44.348z"
                                                }
                                            ],
                                            "realIndex": "1"
                                        },
                                        {
                                            "type": "bar",
                                            "paths": [
                                                {
                                                    "d": "M 110.5 0L 165.7 0Q 165.7 0 165.7 0L 165.7 44.348Q 165.7 44.348 165.7 44.348L 165.7 44.348L 110.5 44.348L 110.5 44.348z"
                                                }
                                            ],
                                            "realIndex": "2"
                                        },
                                        {
                                            "type": "bar",
                                            "paths": [
                                                {
                                                    "d": "M 165.7 0L 268.1 0Q 276.1 0 276.1 8L 276.1 36.348Q 276.1 44.348 268.1 44.348L 268.1 44.348L 165.7 44.348L 165.7 44.348z"
                                                }
                                            ],
                                            "realIndex": "3"
                                        }
                                    ],
                                    "allSeriesHasEqualX": true,
                                    "pointsArray": [],
                                    "dataLabelsRects": [
                                        [
                                            {
                                                "x": 20.8,
                                                "y": 28.174,
                                                "width": 26.607986450195312,
                                                "height": 16
                                            }
                                        ],
                                        [
                                            {
                                                "x": 76,
                                                "y": 28.174,
                                                "width": 28.309982299804688,
                                                "height": 16
                                            }
                                        ],
                                        [
                                            {
                                                "x": 138.1,
                                                "y": 28.174,
                                                "width": 28.869979858398438,
                                                "height": 16
                                            }
                                        ],
                                        [
                                            {
                                                "x": 220.90000000000003,
                                                "y": 28.174,
                                                "width": 29.261978149414062,
                                                "height": 16
                                            }
                                        ]
                                    ],
                                    "lastDrawnDataLabelsIndexes": [
                                        [
                                            0,
                                            1,
                                            2,
                                            3,
                                            0,
                                            1,
                                            2,
                                            0,
                                            1,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            1,
                                            2,
                                            3,
                                            0,
                                            1,
                                            2,
                                            0,
                                            1,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0,
                                            0
                                        ]
                                    ],
                                    "hasNullValues": true,
                                    "zoomed": false,
                                    "gridWidth": 276,
                                    "gridHeight": 46.348,
                                    "rotateXLabels": false,
                                    "defaultLabels": false,
                                    "yLabelFormatters": [
                                        null
                                    ],
                                    "LINE_HEIGHT_RATIO": 1.618,
                                    "xAxisLabelsHeight": 32.652,
                                    "xAxisLabelsWidth": 31.60797119140625,
                                    "yAxisLabelsWidth": 0,
                                    "scaleX": 1,
                                    "scaleY": 1,
                                    "translateX": 30,
                                    "translateY": 30,
                                    "translateYAxisX": [
                                        -3
                                    ],
                                    "yAxisWidths": [],
                                    "translateXAxisY": -8,
                                    "translateXAxisX": 0,
                                    "tooltip": {
                                        "tooltipUtil": {},
                                        "tooltipLabels": {
                                            "tooltipUtil": {}
                                        },
                                        "tooltipPosition": {},
                                        "marker": {
                                            "tooltipPosition": {}
                                        },
                                        "intersect": {},
                                        "axesTooltip": {},
                                        "showOnIntersect": true,
                                        "showTooltipTitle": true,
                                        "fixedTooltip": false,
                                        "xaxisTooltip": null,
                                        "yaxisTTEls": null,
                                        "isBarShared": false,
                                        "lastHoverTime": 1682745958333,
                                        "xyRatios": {
                                            "yRatio": [
                                                2.1575904030378874
                                            ],
                                            "invertedYRatio": 0.36231884057971014,
                                            "zRatio": null,
                                            "xRatio": null,
                                            "initialXRatio": null,
                                            "invertedXRatio": null,
                                            "baseLineInvertedY": 0.1,
                                            "baseLineY": [
                                                0
                                            ],
                                            "baseLineX": 0
                                        },
                                        "isXAxisTooltipEnabled": false,
                                        "yaxisTooltips": [
                                            false
                                        ],
                                        "allTooltipSeriesGroups": [],
                                        "xaxisOffY": 47.348,
                                        "yaxisOffX": 1,
                                        "yaxisTooltip": {},
                                        "yaxisTooltipText": [
                                            {}
                                        ],
                                        "xcrosshairsWidth": 0,
                                        "ycrosshairs": {
                                            "instance": {
                                                "_stroke": "#b6b6b6",
                                                "_event": null,
                                                "dom": {},
                                                "type": "line"
                                            }
                                        },
                                        "ycrosshairsHidden": {
                                            "instance": {
                                                "_stroke": "#b6b6b6",
                                                "_event": null,
                                                "dom": {},
                                                "type": "line"
                                            }
                                        },
                                        "xAxisTicksPositions": [
                                            276
                                        ],
                                        "dataPointsDividedHeight": 46.348,
                                        "dataPointsDividedWidth": 276,
                                        "tooltipTitle": {},
                                        "legendLabels": {
                                            "0": {},
                                            "1": {},
                                            "2": {},
                                            "3": {}
                                        },
                                        "ttItems": [
                                            {},
                                            {},
                                            {},
                                            {}
                                        ],
                                        "seriesBound": {
                                            "x": 1027,
                                            "y": 389,
                                            "width": 277.5,
                                            "height": 46.347991943359375,
                                            "top": 389,
                                            "right": 1304.5,
                                            "bottom": 435.3479919433594,
                                            "left": 1027
                                        }
                                    },
                                    "series": [
                                        [
                                            15
                                        ],
                                        [
                                            25
                                        ],
                                        [
                                            20
                                        ],
                                        [
                                            40
                                        ]
                                    ],
                                    "seriesCandleO": [],
                                    "seriesCandleH": [],
                                    "seriesCandleM": [],
                                    "seriesCandleL": [],
                                    "seriesCandleC": [],
                                    "seriesRangeStart": [],
                                    "seriesRangeEnd": [],
                                    "seriesRangeBar": [],
                                    "seriesPercent": [
                                        [
                                            15
                                        ],
                                        [
                                            25
                                        ],
                                        [
                                            20
                                        ],
                                        [
                                            40
                                        ]
                                    ],
                                    "seriesGoals": [
                                        [
                                            null
                                        ],
                                        [
                                            null
                                        ],
                                        [
                                            null
                                        ],
                                        [
                                            null
                                        ]
                                    ],
                                    "seriesX": [
                                        null,
                                        [
                                            ""
                                        ],
                                        [
                                            ""
                                        ],
                                        [
                                            ""
                                        ]
                                    ],
                                    "seriesZ": [
                                        [],
                                        [],
                                        [],
                                        []
                                    ],
                                    "seriesNames": [
                                        "Level 1",
                                        "Level 2",
                                        "Level 3",
                                        "Level 4"
                                    ],
                                    "seriesTotals": [
                                        doc.data().first,
                                        doc.data().second,
                                        doc.data().third,
                                        doc.data().fourth
                                    ],
                                    "seriesLog": [
                                        null,
                                        null,
                                        null,
                                        null
                                    ],
                                    "seriesColors": [
                                        null,
                                        null,
                                        null,
                                        null
                                    ],
                                    "stackedSeriesTotals": [
                                        100
                                    ],
                                    "seriesXvalues": [
                                        [
                                            41.5
                                        ],
                                        [
                                            110.5
                                        ],
                                        [
                                            165.7
                                        ],
                                        [
                                            276.1
                                        ]
                                    ],
                                    "seriesYvalues": [
                                        [
                                            46.348
                                        ],
                                        [
                                            46.348
                                        ],
                                        [
                                            46.348
                                        ],
                                        [
                                            46.348
                                        ]
                                    ],
                                    "categoryLabels": [],
                                    "selectionResizeTimer": null,
                                    "isXNumeric": false,
                                    "xaxisLabelsCount": 0,
                                    "isMultiLineX": false,
                                    "isMultipleYAxis": false,
                                    "maxY": 100,
                                    "minY": 0,
                                    "minYArr": [
                                        0
                                    ],
                                    "maxYArr": [
                                        100
                                    ],
                                    "maxX": -1.7976931348623157e+308,
                                    "minX": 1.7976931348623157e+308,
                                    "initialMaxX": -1.7976931348623157e+308,
                                    "initialMinX": 1.7976931348623157e+308,
                                    "maxDate": 0,
                                    "minDate": 1.7976931348623157e+308,
                                    "minZ": 1.7976931348623157e+308,
                                    "maxZ": -1.7976931348623157e+308,
                                    "minXDiff": 1.7976931348623157e+308,
                                    "yAxisScale": [
                                        {
                                            "result": [
                                                0,
                                                20,
                                                40,
                                                60,
                                                80,
                                                100
                                            ],
                                            "niceMin": 0,
                                            "niceMax": 100
                                        }
                                    ],
                                    "xAxisScale": null,
                                    "xAxisTicksPositions": [],
                                    "yLabelsCoords": [
                                        {
                                            "width": 0,
                                            "index": 0
                                        }
                                    ],
                                    "yTitleCoords": [
                                        {
                                            "width": 0,
                                            "index": 0
                                        }
                                    ],
                                    "barPadForNumericAxis": 0,
                                    "padHorizontal": 0,
                                    "xRange": null,
                                    "yRange": [
                                        100
                                    ],
                                    "zRange": null,
                                    "dataPoints": 1,
                                    "xTickAmount": 1,
                                    "xyCharts": true,
                                    "isBarHorizontal": true,
                                    "chartClass": ".apexcharts2ElN9",
                                    "comboBarCount": 0,
                                    "isRangeBar": false,
                                    "xAxisHeight": 32.652,
                                    "yLogRatio": [
                                        2.1575904030378874
                                    ],
                                    "logYRange": [
                                        null
                                    ]
                                }
                            },
                            "publicMethods": [
                                "updateOptions",
                                "updateSeries",
                                "appendData",
                                "appendSeries",
                                "toggleSeries",
                                "showSeries",
                                "hideSeries",
                                "setLocale",
                                "resetSeries",
                                "zoomX",
                                "toggleDataPointSelection",
                                "dataURI",
                                "addXaxisAnnotation",
                                "addYaxisAnnotation",
                                "addPointAnnotation",
                                "clearAnnotations",
                                "removeAnnotation",
                                "paper",
                                "destroy"
                            ],
                            "eventList": [
                                "click",
                                "mousedown",
                                "mousemove",
                                "mouseleave",
                                "touchstart",
                                "touchmove",
                                "touchleave",
                                "mouseup",
                                "touchend"
                            ],
                            "animations": {},
                            "axes": {},
                            "core": {},
                            "config": {
                                "opts": {}
                            },
                            "data": {
                                "coreUtils": {},
                                "fallbackToCategory": true,
                                "activeSeriesIndex": 0
                            },
                            "grid": {
                                "xaxisLabels": [
                                    ""
                                ],
                                "axesUtils": {},
                                "isRangeBar": 0,
                                "elg": {
                                    "_stroke": "#000000",
                                    "_event": null,
                                    "dom": {},
                                    "node": {},
                                    "type": "g"
                                },
                                "elgridLinesH": {
                                    "_stroke": "#000000",
                                    "_event": null,
                                    "dom": {},
                                    "node": {},
                                    "type": "g"
                                },
                                "elgridLinesV": {
                                    "_stroke": "#000000",
                                    "_event": null,
                                    "dom": {},
                                    "node": {},
                                    "type": "g"
                                }
                            },
                            "graphics": {},
                            "coreUtils": {},
                            "crosshairs": {},
                            "events": {},
                            "exports": {},
                            "localization": {},
                            "options": {
                                "yAxis": {
                                    "show": true,
                                    "showAlways": false,
                                    "showForNullSeries": true,
                                    "opposite": false,
                                    "reversed": false,
                                    "logarithmic": false,
                                    "logBase": 10,
                                    "forceNiceScale": false,
                                    "floating": false,
                                    "labels": {
                                        "show": true,
                                        "minWidth": 0,
                                        "maxWidth": 160,
                                        "offsetX": 0,
                                        "offsetY": 0,
                                        "rotate": 0,
                                        "padding": 20,
                                        "style": {
                                            "colors": [],
                                            "fontSize": "11px",
                                            "fontWeight": 400,
                                            "cssClass": ""
                                        }
                                    },
                                    "axisBorder": {
                                        "show": false,
                                        "color": "#e0e0e0",
                                        "width": 1,
                                        "offsetX": 0,
                                        "offsetY": 0
                                    },
                                    "axisTicks": {
                                        "show": false,
                                        "color": "#e0e0e0",
                                        "width": 6,
                                        "offsetX": 0,
                                        "offsetY": 0
                                    },
                                    "title": {
                                        "rotate": -90,
                                        "offsetY": 0,
                                        "offsetX": 0,
                                        "style": {
                                            "fontSize": "11px",
                                            "fontWeight": 900,
                                            "cssClass": ""
                                        }
                                    },
                                    "tooltip": {
                                        "enabled": false,
                                        "offsetX": 0
                                    },
                                    "crosshairs": {
                                        "show": true,
                                        "position": "front",
                                        "stroke": {
                                            "color": "#b6b6b6",
                                            "width": 1,
                                            "dashArray": 0
                                        }
                                    }
                                },
                                "pointAnnotation": {
                                    "x": 0,
                                    "y": null,
                                    "yAxisIndex": 0,
                                    "seriesIndex": 0,
                                    "marker": {
                                        "size": 4,
                                        "fillColor": "#fff",
                                        "strokeWidth": 2,
                                        "strokeColor": "#333",
                                        "shape": "circle",
                                        "offsetX": 0,
                                        "offsetY": 0,
                                        "radius": 2,
                                        "cssClass": ""
                                    },
                                    "label": {
                                        "borderColor": "#c2c2c2",
                                        "borderWidth": 1,
                                        "borderRadius": 2,
                                        "textAnchor": "middle",
                                        "offsetX": 0,
                                        "offsetY": 0,
                                        "style": {
                                            "background": "#fff",
                                            "fontSize": "11px",
                                            "fontWeight": 400,
                                            "cssClass": "",
                                            "padding": {
                                                "left": 5,
                                                "right": 5,
                                                "top": 2,
                                                "bottom": 2
                                            }
                                        }
                                    },
                                    "customSVG": {
                                        "offsetX": 0,
                                        "offsetY": 0
                                    },
                                    "image": {
                                        "width": 20,
                                        "height": 20,
                                        "offsetX": 0,
                                        "offsetY": 0
                                    }
                                },
                                "yAxisAnnotation": {
                                    "y": 0,
                                    "y2": null,
                                    "strokeDashArray": 1,
                                    "fillColor": "#c2c2c2",
                                    "borderColor": "#c2c2c2",
                                    "borderWidth": 1,
                                    "opacity": 0.3,
                                    "offsetX": 0,
                                    "offsetY": 0,
                                    "width": "100%",
                                    "yAxisIndex": 0,
                                    "label": {
                                        "borderColor": "#c2c2c2",
                                        "borderWidth": 1,
                                        "borderRadius": 2,
                                        "textAnchor": "end",
                                        "position": "right",
                                        "offsetX": 0,
                                        "offsetY": -3,
                                        "style": {
                                            "background": "#fff",
                                            "fontSize": "11px",
                                            "fontWeight": 400,
                                            "cssClass": "",
                                            "padding": {
                                                "left": 5,
                                                "right": 5,
                                                "top": 2,
                                                "bottom": 2
                                            }
                                        }
                                    }
                                },
                                "xAxisAnnotation": {
                                    "x": 0,
                                    "x2": null,
                                    "strokeDashArray": 1,
                                    "fillColor": "#c2c2c2",
                                    "borderColor": "#c2c2c2",
                                    "borderWidth": 1,
                                    "opacity": 0.3,
                                    "offsetX": 0,
                                    "offsetY": 0,
                                    "label": {
                                        "borderColor": "#c2c2c2",
                                        "borderWidth": 1,
                                        "borderRadius": 2,
                                        "textAnchor": "middle",
                                        "orientation": "vertical",
                                        "position": "top",
                                        "offsetX": 0,
                                        "offsetY": 0,
                                        "style": {
                                            "background": "#fff",
                                            "fontSize": "11px",
                                            "fontWeight": 400,
                                            "cssClass": "",
                                            "padding": {
                                                "left": 5,
                                                "right": 5,
                                                "top": 2,
                                                "bottom": 2
                                            }
                                        }
                                    }
                                },
                                "text": {
                                    "x": 0,
                                    "y": 0,
                                    "text": "",
                                    "textAnchor": "start",
                                    "fontSize": "13px",
                                    "fontWeight": 400,
                                    "appendTo": ".apexcharts-annotations",
                                    "backgroundColor": "transparent",
                                    "borderColor": "#c2c2c2",
                                    "borderRadius": 0,
                                    "borderWidth": 0,
                                    "paddingLeft": 4,
                                    "paddingRight": 4,
                                    "paddingTop": 2,
                                    "paddingBottom": 2
                                }
                            },
                            "responsive": {},
                            "series": {
                                "legendInactiveClass": "legend-mouseover-inactive"
                            },
                            "theme": {
                                "colors": [],
                                "isColorFn": false,
                                "isHeatmapDistributed": false,
                                "isBarDistributed": false
                            },
                            "formatters": {
                                "tooltipKeyFormat": "dd MMM"
                            },
                            "titleSubtitle": {},
                            "legend": {
                                "isBarsDistributed": false,
                                "legendHelpers": {}
                            },
                            "toolbar": {
                                "ev": {},
                                "selectedClass": "apexcharts-selected",
                                "minX": 1.7976931348623157e+308,
                                "maxX": -1.7976931348623157e+308
                            },
                            "dimensions": {
                                "lgRect": {
                                    "x": 1018,
                                    "y": 468,
                                    "height": 19,
                                    "width": 310
                                },
                                "yAxisWidth": 15,
                                "yAxisWidthLeft": 10,
                                "yAxisWidthRight": 0,
                                "xAxisHeight": 32.652,
                                "isSparkline": false,
                                "dimHelpers": {},
                                "dimYAxis": {},
                                "dimXAxis": {},
                                "dimGrid": {},
                                "lgWidthForSideLegends": 0,
                                "xPadRight": 0,
                                "xPadLeft": 0,
                                "xAxisWidth": 31.60797119140625
                            },
                            "updateHelpers": {},
                            "zoomPanSelection": {
                                "selectedClass": "apexcharts-selected",
                                "minX": 1.7976931348623157e+308,
                                "maxX": -1.7976931348623157e+308,
                                "dragged": false,
                                "graphics": {},
                                "eventList": [
                                    "mousedown",
                                    "mouseleave",
                                    "mousemove",
                                    "touchstart",
                                    "touchmove",
                                    "mouseup",
                                    "touchend"
                                ],
                                "clientX": 0,
                                "clientY": 0,
                                "startX": 0,
                                "endX": 0,
                                "dragX": 0,
                                "startY": 0,
                                "endY": 0,
                                "dragY": 0,
                                "moveDirection": "none"
                            },
                            "pie": {
                                "chartType": "bar",
                                "initialAnim": false,
                                "dynamicAnim": false,
                                "animBeginArr": [
                                    0
                                ],
                                "animDur": 0,
                                "defaultSize": 46.348,
                                "centerY": 23.174,
                                "centerX": 138,
                                "fullAngle": 360,
                                "initialAngle": 0,
                                "donutSize": 11.44570731707317,
                                "maxY": 0,
                                "sliceLabels": [],
                                "sliceSizes": [],
                                "prevSectorAngleArr": []
                            },
                            "rangeBar": {
                                "isHorizontal": true,
                                "strokeWidth": 2,
                                "isNullValue": false,
                                "isRangeBar": 0,
                                "xRatio": null,
                                "initialXRatio": null,
                                "invertedXRatio": null,
                                "invertedYRatio": 0.36231884057971014,
                                "baseLineInvertedY": 0.1,
                                "yaxisIndex": 0,
                                "seriesLen": 0,
                                "barHelpers": {}
                            },
                            "annotations": {
                                "graphics": {},
                                "invertAxis": true,
                                "helpers": {},
                                "xAxisAnnotations": {
                                    "invertAxis": true
                                },
                                "yAxisAnnotations": {},
                                "pointsAnnotations": {},
                                "xDivision": 276
                            }
                        }
                    }
                ]
            }

            var chart = new ApexCharts(document.querySelector("#charts"), options);
            chart.render();
        });
    }
    

    useEffect(()=>{

        let timer = setTimeout(()=>{ setShow(true); }, 1000);
        window.scrollTo(0, 0);

        if (total > 16 ){
            $('.comment .result_img').html('<a href="'+result4+'" download="집사의_자격_결과지"><img src="'+result4+'" style="width:95%; margin: 0 auto" /></a>');
        }
        else if( total <=16 && total >12  ){
            $('.comment .result_img').html('<a href="'+result3+'" download="집사의_자격_결과지"><img src="'+result3+'" style="width:95%; margin: 0 auto" /></a>');
        }
        else if( total <=12 && total > 6  ){
            $('.comment .result_img').html('<a href="'+result2+'" download="집사의_자격_결과지"><img src="'+result2+'" style="width:95%; margin: 0 auto" /></a>');
        }
        else{
            $('.comment .result_img').html('<a href="'+result1+'" download="집사의_자격_결과지"><img src="'+result1+'" style="width:95%; margin: 0 auto" /></a>');
        }
    });

    useEffect(()=>{
        window.scrollTo(0, 0);
        getData() 
    },[]);

    return(
        <>
         {
            isOpen === false 
            ? (
                <div class="result_loading">
                    <div class="icon">
                        <img src={img}/>
                    </div>
                    <br/>
                    <div class="stage-name2">결과 완료</div>
                </div>
            )  
               
            : (
                <div class="last_page">
                    <div class="new_header">
                        <img src={logo2} alt="집사의 자격" />
                    </div>
                    <article class="comment">
                        <div class="contents_wrap">
                            <div class="result_img"></div>
                            <p>▲ 이미지 눌러서 저장하기</p>
                        </div>
                    </article>
                    <article class="book_list">
                        <div class="contents_wrap">
                            <div class="top">
                                <span class="pointer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div class="idx">결과 현황</div><br/>
                            </div>
                            <div id="charts"></div>
                        </div>
                    </article>
                    <article class="share">
                        <div class="contents_wrap">
                            <h2>친구에게 테스트 공유하기</h2>
                            <div class="snsShare">
                                <span id="kakao-link-btn"><img src={kakao} onClick={() => shareKakao() } /></span>
                                <span id="f"><img src={fb} onClick={ () => sendSns('facebook','https://zipsa.swygbro.com','집사력 테스트하러가기')} /></span>
                                <span id="t"><img src={tw}  onClick={ () => sendSns('twitter','https://zipsa.swygbro.com','집사력 테스트하러가기') } /></span>
                                <span id="l"><img src={link}  onClick={copy} /></span>
                            </div>
                            <div class="btn_box">
                                <a href="/stage" class="btn"><span>테스트 다시하기</span></a>
                                <a href="/" class="btn gray"><span>메인으로 가기</span></a>
                            </div>
                        </div>
                    </article>
            { total <= 12 || isNaN(total)
                ? (    
                    <article class="book_list">
                        <div class="contents_wrap">
                            <div class="top">
                                <span class="pointer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div class="idx">추천 도서</div><br/>
                            </div>
                            <Swiper
                                className="book_slide"
                                spaceBetween={15}
                                slidesPerView={1.1}
                                scrollbar={{ draggable: true }}
                                breakpoints={{
                                    768: {
                                    slidesPerView: 1,
                                    },
                                }}
                                >
                                        <SwiperSlide>
                                            <a href="https://search.shopping.naver.com/book/catalog/32463732999" target="_blank"><img src={book5} class="book" alt="" /></a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://search.shopping.naver.com/book/catalog/32476736820" target="_blank"><img src={book1} class="book" alt="" /></a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://search.shopping.naver.com/book/catalog/32438182091" target="_blank"><img src={book4} class="book" alt="" /></a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://search.shopping.naver.com/book/catalog/32486002205" target="_blank"><img src={book2} class="book" alt="" /></a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://search.shopping.naver.com/book/catalog/32436281388" target="_blank"><img src={book3} class="book" alt="" /></a>
                                        </SwiperSlide>
                            </Swiper>
                            <p>▲ 이미지 눌러서 구매링크로 가기</p>
                        </div>
                    </article>
                ) : (

                    <article class="book_list">
                    <div class="contents_wrap">
                        <div class="top">
                            <span class="pointer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <div class="idx">사지말고, 입양해주세요</div><br/>
                        </div>
                        <Swiper
                            className="book_slide"
                            spaceBetween={15}
                            slidesPerView={1.1}
                            scrollbar={{ draggable: true }}
                            breakpoints={{
                                768: {
                                slidesPerView: 1,
                                },
                            }}
                            >
                                    <SwiperSlide>
                                        <a href="http://pawinhand.kr/" target="_blank"><img src={adopt1} class="book" alt="포인핸ㄷ" /></a>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <a href="https://www.ekara.org/" target="_blank"><img src={adopt2} class="book" alt="카라" /></a>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <a href="https://www.animals.or.kr/center/adopt" target="_blank"><img src={adopt3} class="book" alt="동물자유연대" /></a>
                                    </SwiperSlide>
                            
                        </Swiper>
                        <p>▲ 이미지 눌러서 해당페이지 가기</p>
                    </div>
                </article>
                )
            }
                    <article class="video_list">
                        <div class="contents_wrap">
                            <div class="top">
                                <span class="pointer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div class="idx">추천 유튜브</div><br/>
                            </div>
                            <Swiper
                                className="video_slide"
                                spaceBetween={15}
                                slidesPerView={1.1}
                                scrollbar={{ draggable: true }}
                                breakpoints={{
                                    768: {
                                    slidesPerView: 1,
                                    },
                                }}
                                >
                                        <SwiperSlide>
                                            <a href="https://www.youtube.com/channel/UClljZ2pw3YZFVGq6EQgJVyQ" target="_blank"><img src={video1} class="video" alt="비마이펫" /></a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://www.youtube.com/channel/UCADTU4uYdeSWKizj8OBKQRA" target="_blank"><img src={video2} class="video" alt="냥신티비" /></a>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <a href="https://www.youtube.com/channel/UC69l_rtlCQ7M4Mz2RCS80BA" target="_blank"><img src={video3} class="video" alt="미야옹철의 냥냥펀치" /></a>
                                        </SwiperSlide>
                                
                            </Swiper>
                            <p>▲ 이미지 눌러서 해당페이지 가기</p>
                        </div>
                    </article>
                    <article class="repo">
                        <div class="contents_wrap">
                            <div class="top">
                                <span class="pointer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div class="idx">참고 출처</div>
                            </div>
                            <p>※ 본 질문지는 해당 사이트를 참고해서 만들었습니다.</p>
                            <a href="https://mypetlife.co.kr/" target="_blank">비마이펫  https://mypetlife.co.kr/</a><br/>
                            <a href="https://apms.epis.or.kr/" target="_blank">동물사랑배움터 https://apms.epis.or.kr/</a>
                        </div>
                    </article>
                    <article>
                        <GoogleAdvertise />
                    </article>
                 </div>
            )

        }
        </>
        
    )
}

export default Result;