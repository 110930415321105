import React, { Component  } from "react";

class GoogleAdvertise extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <ins className = "adsbygoogle"
                style = { {display:"inline-block",width:"320px",height:"100px"} }
                data-ad-client = "ca-pub-1919598055512436"
                data-ad-slot = "2044520891"></ins>
        )
    }
}

export default GoogleAdvertise