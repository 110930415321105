import logo2 from '../../image/logo2.png';
import stage16q from '../../image/1-6-q.png';
import $ from "jquery";
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';


const Option = (props) => {
    let q = props.ask;
    let c = props.choice;
    const [dataQ,setQData]=useState([]);
    //console.log(c[0].txt);

    const getQData=()=>{
        fetch("https://zipsa-testing-default-rtdb.firebaseio.com/quest/dog/"+props.stage+"/quest/"+props.number+".json"
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            return response.json();
          })
          .then(function(myJson) {
            setQData(myJson['img'])
            if(myJson['img'].length>0){
                $("#q-img").css('text-align','center');
                
                    $("#q-img").html('<img src="'+myJson['img']+'" style="width:60%; margin: 0 auto" />');

            }
          });
      }

    useEffect(()=>{
        getQData();

        $('.prograss span').each(function(index, item){ 
            if($(item).attr('id')==(props.stage+'-'+props.number)){
                $(item).addClass('on');
            }
        });
        if(props.stage === 2){
            $('.stage-top #quest').html('Q'+(props.number+6));
        }
        if(props.stage === 3){
            $('.stage-top #quest').html('Q'+(props.number+10));
        }
        if(props.stage === 4){
            $('.stage-top #quest').html('Q'+(props.number+15));
        }
        if(props.stage === 1){
            $('.stage-top #quest').html('Q'+props.number);
        }
        
      }, []);

    return(
        <>
            <header class="header">
            <div class="pc stage">
                <div class="h_wrap">
                    <a href="/" class="logo">
                        <img src={logo2} alt="집사의 자격" />
                    </a>
                    <div class="member_box">
                        <div class="member on">
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile stage">
                <a href="/" class="logo">
                    <img src={logo2} alt="집사의 자격" />
                </a>
                <a href="/" class="btn_menu close">
                    <div class="blind">Menu</div>
                        <span> </span>
                        <span> </span>
                        <span> </span>
                </a>
            </div>
        </header>
        <div class="stage-title">{props.title}</div>
        <div class="prograss">
                <span id="1-1"></span>
                <span id="1-2"></span>
                <span id="1-3"></span>
                <span id="1-4"></span>
                <span id="1-5"></span>
                <span id="1-6"></span>
                <span id="2-1"></span>
                <span id="2-2"></span>
                <span id="2-3"></span>
                <span id="2-4"></span>
                <span id="3-1"></span>
                <span id="3-2"></span>
                <span id="3-3"></span>
                <span id="3-4"></span>
                <span id="3-5"></span>
                <span id="3-6"></span>
                <span id="4-1"></span>
                <span id="4-2"></span>
                <span id="4-3"></span>
                <span id="4-4"></span>  
        </div>
        <br/>
        <article class="testDog">
            <div class="contents_wrap">
                <div class="stage-top" data-aos="fade-up">
                    <h2 id="quest"></h2><br/>
                    <h2 dangerouslySetInnerHTML={{__html:props.ask}}></h2>
                    <div id="q-img"></div>
                    <br/>
                </div>
                <div class="list"  data-aos="fade-up" data-aos-delay="200">
                    <div class="btn_box">
                    {c.map((e, i) => {                                     
        return (
            <Link
                to="/stage/dog/check"
                state={{
                  stage: props.stage,
                  number: props.number,
                  score: c[i].score,
                  txt: c[i].txt
                }}
                class="btn"
                dangerouslySetInnerHTML={ {__html:c[i].txt}}
            >
                      
              </Link>
        ); })}
                    </div>
                   
                </div>
            </div>
        </article>
        </>
    )
}
export default Option;